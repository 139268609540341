import React, { memo } from 'react'
import PersonImg from '../../images/person.png'
import Toggle from './toggle'
import Button from '../form/buttons'
import Modal from 'react-modal'
import IconWarning from '../../images/ic_warning.svg'
import IconCorrect from '../../images/ic_correct.svg'
import IconClose from '../../images/ic_close.svg'
import IconDown from '../../images/ic_down.svg'
import LogoClub from '../../images/club-event/logo.png'
import MediaQuery from 'react-responsive'
import { navigate } from 'gatsby-link'
import StilWorkingData from './components/StilWorkingData'
import styled from 'styled-components'
import swal from 'sweetalert'
import {
  postUpdateProfileInfo,
  postJoinClub,
  listDataPersonalInfo,
  uploadProfileImg,
  getProfileFromId,
  getProfile,
  getPositionFromId,
  updateProfile
} from '../../utils/api'
import { getLanguage } from '../../locale'
import { AuthenticationHelper } from '../../utils/auth-utils'
import moment from 'moment'
import Input from '../form/input'
import s from 'styled-components'
import Cookies from 'universal-cookie'
import TermAndConditionPopup from '../popup/term-condition-popup'
import Loading from '../loading/index'

const cookies = new Cookies()

const SpanAgree = s.span`margin-left:2px;color: #892a24 !important;text-decoration: underline !important;    cursor: pointer;`
const style3 = { textAlign: 'center', padding: 10 }
const styleLoading = { position: 'fix' }
const LineHorizontal = styled.hr`
  border: 0;
  height: 1px;
  background: #979797;
  margin: 20px 0;
`

const SelectBox = styled.select`
  border: 1px solid #3f3131;
  width: ${({ width }) => `${width}`};
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  padding: 5px 5px 5px 10px;
  line-height: 35px;
  border-radius: 5px;
  background: url(${IconDown}) no-repeat right;
  -webkit-appearance: none;
  background-position-x: 90%;
`

const SelectBoxInfo = styled(SelectBox)`
  margin-bottom: 1em;
  padding: 5px 30px 5px 10px;
  background-position-x: 95%;
`

const yearRange = () => {
  const currentYear = new Date().getFullYear()
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  return range(currentYear, currentYear - 120, -1)
}

const monthRange = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  th: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
}

const stilWorkingData = {
  from_year: '',
  from_month: '',
  to_year: '',
  to_month: '',
  position: '',
  company: '',
  business_type: '',
  skill: '',
  business_type_other: '',
  until_now: false,
  start: '',
  end: '',
}

/* eslint-disable jsx-a11y/anchor-is-valid */
class Club extends React.Component {
  static defaultProps = {
    hideBG: true,
    underlineColor: '#C14C40',
  }
  constructor(props) {
    super(props)
    this.refProfileImg = React.createRef()
    this.state = {
      style: null,
      showModal: false,
      focus_id: '',
      modalInfo: {
        icon: '',
        text: '',
      },
      submit: false,
      status: '',
      work_publish: false,
      personal_publish: false,
      firstname: '',
      student_id: '',
      nickname1: '',
      nickname2: '',
      faculty: 'คณะ วิศวกรรมศาสตร์',
      curriculum: '',
      curriculum_other: '',
      major: '',
      major_other: '',
      class_of: '',
      class_year: '',
      phone_number: '',
      check_phone_number: false,
      line_id: '',
      work_information: [stilWorkingData],
      current_address: '',
      email: '',
      password: '',
      facebook: '',
      list: null,
      selectedFile: null,
      imagePreviewUrl: null,
      profile_img_url: null,
      isPermission: '',
      pdpaAgree: false,
      ui: 'INDEX',
      isLoading: false,
    }
    this.togglePdpaAgree = this.togglePdpaAgree.bind(this)
    this.onTermsClick = this.onTermsClick.bind(this)
    this.onPolicyClick = this.onPolicyClick.bind(this)
    this.closeTermAndConditionPopup = this.closeTermAndConditionPopup.bind(this)
  }

  async componentDidMount() {
    if (
      !cookies.get('_id') ||
      !this.props.clubRegister ||
      !this.props.clubRegister.user_info.firstname
    ) {
      const resData = await getProfile()
      if (resData.data && resData.data.username) {
        const userData = await getProfileFromId(resData.data.username)
        if (userData && userData.data && userData.data._id) {
          cookies.set('_id', userData.data._id, {
            path: '/',
            expires: new Date(
              moment()
                .add(1, 'day')
                .format()
            ),
          })
          this.props.onSetProfile({
            ...userData.data,
          })
        }
      } else {
        navigate(`/club/KMITLEngineerAlumni/`)
        return
      }
    }
    const positionData = await getPositionFromId(cookies.get('_id'))
    if (positionData && positionData.data) {
      this.setState({ isPermission: positionData.data.position || '' })
    }
    const {
      firstname,
      lastname,
      student_id,
      email,
      nickname1,
      nickname2,
      phone_number,
      work_information,
      curriculum_other,
      major_other,
      class_of,
      class_year,
      line_id,
      current_address,
      facebook,
      password,
    } = this.props.clubRegister.user_info
    this.setState({
      firstname: `${firstname}${lastname ? ' ' : ''}${lastname}`,
      student_id,
      email: email || '',
      nickname1,
      nickname2,
      phone_number,
      work_information: work_information || [],
      curriculum_other,
      major_other,
      class_of,
      class_year,
      line_id,
      current_address,
      facebook,
      password,
    })
    this.handleSetInputChange('add')
    const list = await listDataPersonalInfo()
    this.setState({ list: list.data })
    const w = document.documentElement.clientWidth
    const bgColor = this.props.hideBG ? null : { backgroundColor: '#E8E8E8' }
    if (w <= 450) {
      this.setState({ style: { padding: '20px 10px', ...bgColor } })
    } else {
      this.setState({ style: { padding: '20px 0', ...bgColor } })
    }
    if (this.props.clubRegister && this.props.clubRegister.joined) {
      setTimeout(() => {
        const newState = {
          ...this.props.clubRegister.user_info,
          imagePreviewUrl: this.props.clubRegister.user_info.profile_img_url,
        }
        delete newState.firstname
        delete newState.lastname
        delete newState.student_id
        this.setState(newState)
      }, 0)
    }
  }
  componentWillUnmount() {
    this.handleSetInputChange('remove')
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  handleOnChange = (e, key) => {
    this.setState({ [key]: e.target.value })
  }

  togglePdpaAgree = () => this.setState({ pdpaAgree: !this.state.pdpaAgree })
  onTermsClick = () => this.setState({ ui: 'TERM' })
  onPolicyClick = () => this.setState({ ui: 'POLICY' })
  closeTermAndConditionPopup = () => this.setState({ ui: 'INDEX' })

  // handleOnChangeClassOf = (e, key) => {
  //   const isNumber = /^[0-9\b]+$/
  //   if (isNumber.test(e.target.value) || e.target.value === '') {
  //     this.setState({ [key]: e.target.value })
  //   }
  // }

  // handleOnChangeStudentId = (e, key) => {
  //   const isNumber = /^[0-9\b]+$/
  //   if (isNumber.test(e.target.value) || e.target.value === '') {
  //     this.setState({ [key]: e.target.value })
  //   }
  // }

  // handleOnChangeEmailAndPassword = (e, key) => {
  //   this.setState({ [key]: e.target.value })
  //   return
  // }
  // handleOnChangePhoneNumber = (e, key) => {
  //   const isNumber = /^[0-9\b]+$/
  //   if (isNumber.test(e.target.value) || e.target.value === '') {
  //     if (key === 'phone_number') {
  //       this.setState({ check_phone_number: false })
  //       if (e.target.value.length <= 10) {
  //         this.setState({ [key]: e.target.value })
  //       }
  //     }
  //   }
  //   return
  // }
  handleSetInputChange = status => {
    const {
      firstname,
      student_id,
      nickname1,
      nickname2,
      curriculum_other,
      major_other,
      class_of,
      class_year,
      line_id,
      current_address,
      email,
      phone_number,
      facebook,
      password,
      curriculum,
      major,
    } = this.state
    if (status === 'add') {
      // add firstname
      document.getElementById(`firstname`).value = firstname || ''
      document
        .getElementById(`firstname`)
        .addEventListener('change', e => this.handleOnChange(e, 'firstname'))

      // add student_id
      document.getElementById(`student_id`).value = student_id || ''
      document
        .getElementById(`student_id`)
        .addEventListener('change', e => this.handleOnChange(e, 'student_id'))

      // add nickname1
      document.getElementById(`nickname1`).value = nickname1 || ''
      document
        .getElementById(`nickname1`)
        .addEventListener('change', e => this.handleOnChange(e, 'nickname1'))

      // add nickname2
      document.getElementById(`nickname2`).value = nickname2 || ''
      document
        .getElementById(`nickname2`)
        .addEventListener('change', e => this.handleOnChange(e, 'nickname2'))

      if (curriculum === '7') {
        // add curriculum_other
        document.getElementById(`curriculum_other`).value =
          curriculum_other || ''
        document
          .getElementById(`curriculum_other`)
          .addEventListener('change', e =>
            this.handleOnChange(e, 'curriculum_other')
          )
      }

      if (major === '35') {
        // add major_other
        document.getElementById(`major_other`).value = major_other || ''
        document
          .getElementById(`major_other`)
          .addEventListener('change', e =>
            this.handleOnChange(e, 'major_other')
          )
      }

      // add class_of
      document.getElementById(`class_of`).value = class_of || ''
      document
        .getElementById(`class_of`)
        .addEventListener('change', e => this.handleOnChange(e, 'class_of'))

      // add class_year
      document.getElementById(`class_year`).value = class_year || ''
      document
        .getElementById(`class_year`)
        .addEventListener('change', e => this.handleOnChange(e, 'class_year'))

      // add line_id
      document.getElementById(`line_id`).value = line_id || ''
      document
        .getElementById(`line_id`)
        .addEventListener('change', e => this.handleOnChange(e, 'line_id'))

      // add current_address
      document.getElementById(`current_address`).value = current_address || ''
      document
        .getElementById(`current_address`)
        .addEventListener('change', e =>
          this.handleOnChange(e, 'current_address')
        )

      // add email
      document.getElementById(`email`).value = email || ''
      document
        .getElementById(`email`)
        .addEventListener('change', e => this.handleOnChange(e, 'email'))

      // add phone_number
      document.getElementById(`phone_number`).value = phone_number || ''
      document
        .getElementById(`phone_number`)
        .addEventListener('change', e => this.handleOnChange(e, 'phone_number'))

      // add facebook
      document.getElementById(`facebook`).value = facebook || ''
      document
        .getElementById(`facebook`)
        .addEventListener('change', e => this.handleOnChange(e, 'facebook'))

      // add password
      if (document.getElementById(`password`)) {
        document.getElementById(`password`).value = password || ''
        document
          .getElementById(`password`)
          .addEventListener('change', e => this.handleOnChange(e, 'password'))
      }
    }
    if (status === 'remove') {
      // remove firstname
      document
        .getElementById(`firstname`)
        .removeEventListener('change', e => this.handleOnChange(e, 'firstname'))

      // remove student_id
      document
        .getElementById(`student_id`)
        .removeEventListener('change', e =>
          this.handleOnChange(e, 'student_id')
        )

      // remove nickname1
      document
        .getElementById(`nickname1`)
        .removeEventListener('change', e => this.handleOnChange(e, 'nickname1'))

      // remove nickname2
      document
        .getElementById(`nickname2`)
        .removeEventListener('change', e => this.handleOnChange(e, 'nickname2'))

      if (curriculum === '7') {
        // remove curriculum_other
        document
          .getElementById(`curriculum_other`)
          .removeEventListener('change', e =>
            this.handleOnChange(e, 'curriculum_other')
          )
      }

      if (major === '35') {
        // remove major_other
        document
          .getElementById(`major_other`)
          .removeEventListener('change', e =>
            this.handleOnChange(e, 'major_other')
          )
      }

      // remove class_of
      document
        .getElementById(`class_of`)
        .removeEventListener('change', e => this.handleOnChange(e, 'class_of'))

      // remove class_year
      document
        .getElementById(`class_year`)
        .removeEventListener('change', e =>
          this.handleOnChange(e, 'class_year')
        )

      // remove line_id
      document
        .getElementById(`line_id`)
        .removeEventListener('change', e => this.handleOnChange(e, 'line_id'))

      // remove current_address
      document
        .getElementById(`current_address`)
        .removeEventListener('change', e =>
          this.handleOnChange(e, 'current_address')
        )

      // remove email
      document
        .getElementById(`email`)
        .removeEventListener('change', e => this.handleOnChange(e, 'email'))

      // remove phone_number
      document
        .getElementById(`phone_number`)
        .removeEventListener('change', e =>
          this.handleOnChange(e, 'phone_number')
        )

      // remove facebook
      document
        .getElementById(`facebook`)
        .removeEventListener('change', e => this.handleOnChange(e, 'facebook'))

      // remove password
      if (document.getElementById(`password`)) {
        document
          .getElementById(`password`)
          .removeEventListener('change', e =>
            this.handleOnChange(e, 'password')
          )
      }
    }
  }
  handleOpenModal = type => {
    if (type === 'warning') {
      this.setState({
        showModal: true,
        modalInfo: {
          type: 'warning',
          icon: IconWarning,
          text: this.props.locale['WARNING_REGISTER_KMITL'],
          action: () => this.setState({ showModal: false }),
        },
      })
    } else if (type === 'success') {
      this.setState({
        showModal: true,
        modalInfo: {
          icon: IconCorrect,
          text: this.props.locale['SUCCESS_REGISTER_KMITL'],

          action: () => navigate(`/my-club`),
          // action: () => this.setState({showModal: false}), //TODO: revert action
        },
      })
    }
  }
  handleSetWorkInformation = (itemChange, i) => {
    let listArray = this.state.work_information.map((item, idx) => {
      if (idx === i) {
        return itemChange
      }
      return item
    })
    this.setState({
      work_information: listArray || [],
    })
    return
  }
  fileChangedHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
    })

    let reader = new FileReader()

    reader.onloadend = async () => {
      this.setState({
        imagePreviewUrl: reader.result,
      })
      const data = await uploadProfileImg({ data_url: reader.result })

      if (data.data) {
        this.setState({ profile_img_url: data.data.image_url })
      }
    }

    reader.readAsDataURL(event.target.files[0])
  }

  handleSubmitForm = async () => {
    try {
      const {
        firstname,
        student_id,
        nickname1,
        nickname2,
        faculty,
        curriculum,
        major,
        class_of,
        class_year,
        phone_number,
        line_id,
        work_information,
        current_address,
        email,
        password,
        facebook,
        curriculum_other,
        major_other,
        profile_img_url,
        status,
        work_publish,
        personal_publish,
      } = this.state
      if (!phone_number || (phone_number && phone_number.length < 10)) {
        this.setState({ check_phone_number: true, isLoading: false })
        return
      }
      const { from_year, from_month, to_year, to_month } =
        work_information[0] || {}
      let getDateNow = new Date()
      let getStart =
        (from_month &&
          from_year &&
          `${monthRange[this.props.lang.toLowerCase()][from_month]}`) ||
        null
      let getStartYear = from_year ? yearRange()[from_year] : undefined
      if (getStartYear) {
        getStartYear =
          getLanguage().lang === 'EN' ? getStartYear : (getStartYear || 0) + 543
        getStart = getStart ? `${getStart} ${getStartYear}` : null
      }
      let getEnd =
        (to_month &&
          to_year &&
          `${monthRange[this.props.lang.toLowerCase()][to_month]}`) ||
        `${monthRange[this.props.lang.toLowerCase()][getDateNow.getMonth()]}`
      let getEndYear = from_year
        ? yearRange()[from_year]
        : getDateNow.getFullYear()
      getEndYear = getLanguage().lang === 'EN' ? getEndYear : getEndYear + 543
      getEnd = `${getEnd} ${getEndYear}`
      let newWorkInformation = work_information || []
      if (newWorkInformation && newWorkInformation.length > 0) {
        newWorkInformation[0].start = getStart
        newWorkInformation[0].end = getEnd
      }
      let getValueWorkInformation = []
      for (let i = 0; i < newWorkInformation.length; i++) {
        let from_year,
          from_month,
          to_year,
          to_month,
          until_now,
          position,
          company,
          business_type,
          business_type_other,
          skill = undefined
        if (document.getElementById(`from_year${i}`)) {
          from_year = document.getElementById(`from_year${i}`).value
        }
        if (document.getElementById(`from_month${i}`)) {
          from_month = document.getElementById(`from_month${i}`).value
        }
        if (document.getElementById(`until_now${i}`)) {
          until_now = document.getElementById(`until_now${i}`).checked
        }
        if (document.getElementById(`to_year${i}`)) {
          to_year = document.getElementById(`to_year${i}`).value
        }
        if (document.getElementById(`to_month${i}`)) {
          to_month = document.getElementById(`to_month${i}`).value
        }
        if (document.getElementById(`position${i}`)) {
          position = document.getElementById(`position${i}`).value
        }
        if (document.getElementById(`company${i}`)) {
          company = document.getElementById(`company${i}`).value
        }
        if (document.getElementById(`business_type${i}`)) {
          business_type = document.getElementById(`business_type${i}`).value
        }
        if (document.getElementById(`business_type_other${i}`)) {
          business_type_other = document.getElementById(
            `business_type_other${i}`
          ).value
        }
        if (document.getElementById(`skill${i}`)) {
          skill = document.getElementById(`skill${i}`).value
        }
        getValueWorkInformation.push({
          from_year,
          from_month,
          to_year,
          until_now,
          to_month,
          position,
          company,
          business_type,
          business_type_other,
          skill,
        })
      }
      const user_info = {
        firstname:
          firstname && firstname.split(' ').length > 0
            ? firstname.split(' ')[0]
            : firstname,
        lastname:
          firstname && firstname.split(' ').length > 1
            ? firstname.split(' ')[1]
            : '',
        nickname1,
        nickname2,
        student_id,
        faculty,
        curriculum,
        curriculum_other,
        major,
        major_other,
        class_of,
        class_year,
        phone_number,
        line_id,
        work_information: getValueWorkInformation || [],
        current_address,
        email,
        password,
        facebook,
        profile_img_url,
        status,
        work_publish,
        personal_publish,
      }
      let getCognito
      if (!this.props.clubRegister.joined) {
        getCognito = await new Promise(resolve => {
          AuthenticationHelper.signIn({
            username: email,
            password: password,
            onSuccess: userDetail => resolve(userDetail),
            onError: error => {
              var phoneNumber = phone_number
              if (phoneNumber.length > 0) {
                if (phoneNumber.length === 10) {
                  phoneNumber = phoneNumber.slice(1)
                  phoneNumber = '+66' + phoneNumber
                } else {
                  this.setState({ focus_id: 'phone_number' })
                  return
                }
              }
              AuthenticationHelper.signUp({
                fullName: user_info.firstname + ' ' + user_info.lastname,
                email: email,
                phone_number: phoneNumber,
                password: password,
                password2: password,
                onSuccess: reSignin => {
                  AuthenticationHelper.signIn({
                    username: email,
                    password: password,
                    onSuccess: userDetail => resolve(userDetail),
                    onError: errorMessage => {
                      this.setState({ isLoading: false })
                      swal(this.props.locale.ERROR, errorMessage + '!', 'error')
                    }
                  })
                },
                onError: errorMessage => {
                  this.setState({ isLoading: false })
                  swal(this.props.locale.ERROR, errorMessage + '!', 'error')
                }
              })
            },
          })
        })
      }
      const data = await postUpdateProfileInfo({
        _id: cookies.get('_id'),
        invitree_username: (getCognito && getCognito.username) || undefined,
        user_info: { ...this.props.clubRegister.user_info, ...user_info },
        joined: true,
      }).catch(e => {
        swal(this.props.locale.ERROR, e.error || e.message + '!', 'error')
        throw Error(e.error || e.message)
      })
      if (data.data) {
        const dataJoinClub = await postJoinClub({
          firstname:
            firstname && firstname.split(' ').length > 0
              ? firstname.split(' ')[0]
              : firstname,
          lastname:
            firstname && firstname.split(' ').length > 1
              ? firstname.split(' ')[1]
              : '',
          student_id,
          class_of,
          is_active: true,
          user_of: this.props.clubRegister.user_of,
        }).catch(e => {
          throw Error(e.error || e.message)
        })
        if (dataJoinClub) {
          this.props.onSetProfile({
            ...this.props.clubRegister,
            joined: true,
          })
          // update pdpaverified to user
          const pdpaData = await getProfile()
          if (pdpaData.data.pdpaverified === false) {
            const submitPayload = {
              phone_number: pdpaData.data.phone_number,
              name: pdpaData.data.name,
              pdpaverified: this.state.pdpaAgree
            }
            updateProfile(submitPayload)
          }
          this.setState({ isLoading: false })
          this.handleOpenModal('success')
          this.props.onSetProfile({ user_info: user_info })
        }
      }
    } catch (e) {
      console.log('e', e)
      swal(this.props.locale.ERROR, e.message + '!', 'error')
    }
  }

  styleFont1 = { fontFamily: `'Noto Serif', serif` }

  display(props) {
    const {
      firstname,
      student_id,
      curriculum,
      nickname1,
      nickname2,
      faculty,
      major,
      class_of,
      class_year,
      phone_number,
      line_id,
      work_information,
      current_address,
      facebook,
      curriculum_other,
      major_other,
      status,
      work_publish,
      personal_publish,
      list,
    } = this.state
    return (
      <div>
        <TermAndConditionPopup
          closeTermAndConditionPopup={this.closeTermAndConditionPopup}
          ui={this.state.ui}
          lang={this.props.lang}
        />
        <div className="row no-gutters club" style={this.state.style}>
          <div style={{ width: '100%' }}>
            <div className="logo-club-event">
              <img src={LogoClub} alt="logo club event" />
            </div>
            <div className="club__header">
              <hr className="club__line" />
              <h3 style={this.styleFont1}>KMITL Engineer Alumni</h3>
            </div>
            <div className="club__sub-header">
              <p>{props.locale['UPDATE_INFORMATION']}</p>
            </div>
            <div className="club__card club__card-personal">
              <h3>{props.locale['PERSONAL_INFORMATION']}</h3>
              <hr />
              <div className="information__container">
                <div className="information__left">
                  <div className="information__user-img__container">
                    <img
                      src={
                        this.state.imagePreviewUrl
                          ? this.state.imagePreviewUrl
                          : PersonImg
                      }
                      alt=""
                      className="information__user-img"
                    />
                    <i
                      className="fas fa-pencil-alt information__user-img__icon"
                      onClick={() => this.refProfileImg.current.click()}
                    />
                    <input
                      type="file"
                      hidden
                      ref={this.refProfileImg}
                      onChange={this.fileChangedHandler}
                    />
                  </div>
                  <div className="information__member">
                    <div>
                      <p className="information__label">
                        {props.locale['CLUB_STATUS']}
                      </p>
                      <p className="information__value">
                        {
                          props.locale[
                          this.state.isPermission
                            ? this.state.isPermission.toUpperCase()
                            : 'MEMBER'
                          ]
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="information__right">
                  <div className="flex flex-column-mobile">
                    <div className="flex-auto mg-r-40">
                      <p className="information__label">
                        {props.locale['NAME_AND_FAMILY_NAME']}
                      </p>
                      <input
                        id="firstname"
                        type="text"
                        placeholder={props.locale['ENTER_NAME_AND_FAMILY_NAME']}
                        className="information__input"
                      // value={this.state.firstname}
                      // onChange={e => this.handleOnChange(e, 'firstname')}
                      />
                    </div>
                    <div className="flex-auto">
                      <p className="information__label">
                        {props.locale['STUDENT_ID']}
                      </p>
                      <input
                        type="number"
                        id="student_id"
                        // value={
                        //   this.state.student_id === 'NULL'
                        //     ? ''
                        //     : this.state.student_id
                        // }
                        // onChange={e =>
                        //   this.handleOnChangeStudentId(e, 'student_id')
                        // }
                        placeholder={props.locale['ENTER_STUDENT_ID']}
                        className="information__input"
                      />
                    </div>
                  </div>
                  <div className="flex flex-column-mobile">
                    <div className="flex-auto mg-r-40">
                      <p className="information__label">
                        {props.locale['NICKNAME1']}
                      </p>
                      <input
                        id="nickname1"
                        type="text"
                        placeholder={props.locale['ENTER_NICKNAME1']}
                        className="information__input"
                      // value={nickname1}
                      // onChange={e => this.handleOnChange(e, 'nickname1')}
                      />
                    </div>
                    <div className="flex-auto">
                      <p className="information__label">
                        {props.locale['NICKNAME2']}
                      </p>
                      <input
                        type="text"
                        placeholder={props.locale['ENTER_NICKNAME2']}
                        className="information__input"
                        id="nickname2"
                      // value={nickname2}
                      // onChange={e => this.handleOnChange(e, 'nickname2')}
                      />
                    </div>
                  </div>

                  <div className="flex flex-column-mobile">
                    <div className="flex-auto mg-r-40">
                      <p className="information__label">
                        {props.locale['FACULTY']}
                      </p>
                      <input
                        type="text"
                        disabled={true}
                        placeholder={props.locale['ENTER_FACULTY']}
                        className="information__input"
                        value={faculty}
                        onChange={e => this.handleOnChange(e, 'faculty')}
                      />
                    </div>
                    <div className="flex-auto">
                      <p className="information__label">
                        {props.locale['CURRICULUM']}
                      </p>
                      {list && (
                        <SelectBoxInfo
                          name="cirriculum"
                          width={'100%'}
                          onChange={e => this.handleOnChange(e, 'curriculum')}
                          value={curriculum}
                        >
                          <option value="">{props.locale['CHOOSE']}</option>
                          {list.curriculum[this.props.lang.toLowerCase()].map(
                            (item, index) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.value}
                                </option>
                              )
                            }
                          )}
                        </SelectBoxInfo>
                      )}
                      <div className="flex flex-column">
                        <div className="flex-auto">
                          {this.state.curriculum === '7' && (
                            <input
                              id="curriculum_other"
                              type="text"
                              placeholder={props.locale['ENTER_CURRICULUM']}
                              className="information__input"
                            // onChange={e =>
                            //   this.handleOnChange(e, 'curriculum_other')
                            // }
                            // value={curriculum_other}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-column-mobile">
                    <div className="flex-auto">
                      <p className="information__label">
                        {props.locale['MAJOR']}
                      </p>
                      {list && (
                        <SelectBoxInfo
                          name="major"
                          width={'100%'}
                          onChange={e => this.handleOnChange(e, 'major')}
                          value={major}
                        >
                          <option value="">{props.locale['CHOOSE']}</option>
                          {list.major[this.props.lang.toLowerCase()].map(
                            (item, index) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.value}
                                </option>
                              )
                            }
                          )}
                        </SelectBoxInfo>
                      )}
                      <div className="flex flex-column">
                        <div className="flex-auto">
                          {this.state.major === '35' && (
                            <input
                              id="major_other"
                              type="text"
                              placeholder={props.locale['ENTER_MAJOR']}
                              className="information__input"
                            // onChange={e =>
                            //   this.handleOnChange(e, 'major_other')
                            // }
                            // value={major_other}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex-auto mg-r-40" />
                  </div>
                  <div className="flex flex-column-mobile">
                    <div className="flex-auto mg-r-40">
                      <p className="information__label">
                        {props.locale['CLASS_OF']}
                      </p>
                      <input
                        id="class_of"
                        type="number"
                        placeholder={props.locale['ENTER_CLASS_OF']}
                        className="information__input"
                      // onChange={e => this.handleOnChangeClassOf(e, 'class_of')}
                      // value={class_of}
                      />
                    </div>
                    <div className="flex-auto">
                      <p className="information__label">
                        {props.locale['CLASS_YEAR']}
                      </p>
                      <input
                        id="class_year"
                        type="text"
                        placeholder={props.locale['ENTER_CLASS_YEAR']}
                        className="information__input"
                      // onChange={e => this.handleOnChange(e, 'class_year')}
                      // value={class_year}
                      />
                    </div>
                  </div>

                  <div className="flex flex-column-mobile">
                    <div className="flex-auto">
                      <p className="information__label">
                        {props.locale['LINE_ID']}
                      </p>
                      <input
                        id="line_id"
                        type="text"
                        placeholder={props.locale['ENTER_LINE_ID']}
                        className="information__input"
                      // onChange={e => this.handleOnChange(e, 'line_id')}
                      // value={line_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="club__card">
              <MediaQuery maxWidth={768}>
                <div className="flex flex-column align-flex-end">
                  <Toggle
                    name="work_publish"
                    isOn={work_publish}
                    onWord={props.locale['BTN_ON_PUBLISH']}
                    offWord={props.locale['BTN_OFF_PUBLISH']}
                    handleToggle={this.handleInputChange}
                  />
                  {(work_publish && (
                    <span className="alert_warning">
                      {props.locale['WARNING_PUBLISH']}
                    </span>
                  )) ||
                    null}
                </div>
              </MediaQuery>
              <div className="information__status__container flex align-center">
                <label
                  className={`checkbox-label flex-auto ${status === 'retired' ? 'checkbox-label--disabled' : ''
                    }`}
                >
                  {props.locale['STILL_WORKING']}
                  <input
                    name="status"
                    type="radio"
                    value="working"
                    checked={status === 'working'}
                    onChange={this.handleInputChange}
                  />
                  <span
                    className={`checkmark ${status === 'retired' ? 'checkmark--disabled' : ''
                      }`}
                  />
                </label>
                <label
                  className={`checkbox-label flex-auto ${status === 'working' ? 'checkbox-label--disabled' : ''
                    }`}
                >
                  {props.locale['RETIRED']}
                  <input
                    name="status"
                    type="radio"
                    value="retired"
                    checked={status === 'retired'}
                    onChange={this.handleInputChange}
                  />
                  <span
                    className={`checkmark ${status === 'working' ? 'checkmark--disabled' : ''
                      }`}
                  />
                </label>
                <MediaQuery minWidth={769}>
                  <div className="flex flex-column align-flex-end">
                    {status && (
                      <>
                        <Toggle
                          name="work_publish"
                          isOn={work_publish}
                          onWord={props.locale['BTN_ON_PUBLISH']}
                          offWord={props.locale['BTN_OFF_PUBLISH']}
                          handleToggle={this.handleInputChange}
                        />
                        {(work_publish && (
                          <span className="alert_warning">
                            {props.locale['WARNING_PUBLISH']}
                          </span>
                        )) ||
                          null}
                      </>
                    )}
                  </div>
                </MediaQuery>
              </div>
              {status && work_information && work_information.length >= 0 && (
                <React.Fragment>
                  {work_information.map((item, i) => {
                    return (
                      <StilWorkingData
                        key={`renderstill_${i}`}
                        item={item}
                        props={this.props}
                        list={this.state.list}
                        handleSetWorkInformation={this.handleSetWorkInformation}
                        i={i}
                      />
                    )
                  })}
                  <button
                    style={{ color: '#892A24', marginTop: 20 }}
                    onClick={() => {
                      this.setState({
                        work_information: [
                          ...this.state.work_information,
                          stilWorkingData,
                        ],
                      })
                    }}
                  >
                    + {props.locale['ADD_WORKPLACE']}
                  </button>
                </React.Fragment>
              )}
            </div>
            <div className="club__card">
              <div className="flex flex-column align-flex-end">
                <Toggle
                  name="personal_publish"
                  isOn={personal_publish}
                  onWord={props.locale['BTN_ON_PUBLISH']}
                  offWord={props.locale['BTN_OFF_PUBLISH']}
                  handleToggle={() =>
                    this.setState({
                      personal_publish: !this.state.personal_publish,
                    })
                  }
                />
                {(personal_publish && (
                  <span className="alert_warning">
                    {props.locale['WARNING_PUBLISH']}
                  </span>
                )) ||
                  null}
              </div>
              <div className="flex">
                <div className="flex-auto ">
                  <p className="information__label">
                    {props.locale['CURRENT_ADDRESS']}
                  </p>
                  <input
                    id="current_address"
                    type="text"
                    placeholder={props.locale['ENTER_YOUR_CURRENT_ADDRESS']}
                    className="information__input"
                  // onChange={e => this.handleOnChange(e, 'current_address')}
                  // value={current_address}
                  />
                </div>
              </div>
              <div className="flex flex-column-mobile">
                <div className="flex-auto mg-r-40">
                  <p className="information__label">
                    {props.locale['E_MAIL']}{' '}
                    <span style={{ color: '#892A24' }}>
                      * อีเมล์นี้จะถูกใช้เป็นชื่อผู้ใช้งานในการเข้าระบบ
                    </span>
                  </p>
                  <input
                    id="email"
                    type="text"
                    placeholder={props.locale['ENTER_YOUR_E_MAIL']}
                    className="information__input"
                    style={{
                      borderBottom:
                        this.state.submit && !this.state.email
                          ? '1px solid #892A24'
                          : '',
                    }}
                    // value={this.state.email}
                    // onChange={e =>
                    //   this.handleOnChangeEmailAndPassword(e, 'email')
                    // }
                    onFocus={() =>
                      this.state.submit ? this.setState({ submit: false }) : null
                    }
                  />
                  {this.state.submit && !this.state.email && (
                    <p style={{ color: '#892A24' }}>
                      {this.props.locale['ERROR_REGISTER_EMAIL']}
                      <span style={{ color: '#892A24' }}>*</span>
                    </p>
                  )}
                </div>
                <div className="flex-auto mg-r-40">
                  <p className="information__label">
                    {props.locale['PHONE_NUMBER']}{' '}
                    <span style={{ color: '#892A24' }}>*</span>
                  </p>
                  <input
                    id="phone_number"
                    type="number"
                    placeholder={props.locale['ENTER_PHONE_NUMBER']}
                    className="information__input"
                    style={{
                      borderBottom:
                        this.state.focus_id === 'phone_number'
                          ? '1px solid #892A24'
                          : '',
                    }}
                    // onChange={e =>
                    //   this.handleOnChangePhoneNumber(e, 'phone_number')
                    // }
                    // value={phone_number}
                    onFocus={() => this.setState({ focus_id: 'phone_number' })}
                    onBlur={() =>
                      this.state.focus_id ? this.setState({ focus_id: '' }) : null
                    }
                  />
                  {this.state.focus_id === 'phone_number' ||
                    this.state.check_phone_number ? (
                      <span className="alert_warning">
                        {this.props.locale['ERROR_REGISTER_PHONE']}
                      </span>
                    ) : null}
                </div>
                <div className="flex-auto">
                  <p className="information__label">{props.locale['FACEBOOK']}</p>
                  <input
                    id="facebook"
                    type="text"
                    placeholder={props.locale['ENTER_YOUR_FACEBOOK_NAME']}
                    className="information__input"
                  // onChange={e => this.handleOnChange(e, 'facebook')}
                  // value={facebook}
                  />
                </div>
              </div>
              {(!this.props.clubRegister.joined && (
                <div style={{ display: 'inline-block' }}>
                  <div className="flex-auto mg-r-40">
                    <p className="information__label">
                      {props.locale['PASSWORD']}{' '}
                      <span style={{ color: '#892A24' }}>
                        * รหัสผ่านนี้จะถูกใช้ในการเข้าระบบ
                      </span>
                      <p style={{ color: '#892A24' }}> รหัสผ่านต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่, ตัวอักษรพิมพ์เล็ก และตัวเลขเป็นอย่างน้อย </p>
                    </p>
                    <input
                      id="password"
                      type="password"
                      placeholder={props.locale['PLEASE_ENTER_PASSWORD']}
                      className="information__input"
                      style={{
                        borderBottom:
                          this.state.submit && !this.state.password
                            ? '1px solid #892A24'
                            : '',
                      }}
                      // value={this.state.password}
                      // onChange={e =>
                      //   this.handleOnChangeEmailAndPassword(e, 'password')
                      // }
                      onFocus={() =>
                        this.state.submit
                          ? this.setState({ submit: false })
                          : null
                      }
                    />
                    {this.state.submit && !this.state.password && (
                      <p style={{ color: '#892A24' }}>
                        {this.props.locale['ERROR_REGISTER_PASSWORD']}
                        <span style={{ color: '#892A24' }}>*</span>
                      </p>
                    )}
                  </div>
                </div>
              )) ||
                null}
            </div>
            {/* Term and condition display when pdpaverified is false or undefined*/}
            {this.props.authState.pdpaVerified === true ?
              (null) :
              (<div style={style3}>
                <Input.CheckBox
                  value={this.state.pdpaAgree}
                  onChange={this.togglePdpaAgree}
                >
                  {this.props.locale.I_AGREE[0]}
                  <SpanAgree onClick={this.onTermsClick}>
                    {this.props.locale.I_AGREE[1]}
                  </SpanAgree>{' '}
                  {this.props.locale.I_AGREE[2]}
                  <SpanAgree onClick={this.onPolicyClick}>
                    {this.props.locale.I_AGREE[3]}
                  </SpanAgree>
                </Input.CheckBox>
              </div>)
            }
            <div className="flex justify-center information__button__container">
              {/* <Button.Button8
                style={{ padding: 0, width: 164, margin: '0 12px' }}
                onClick={() => {
                  this.handleOpenModal('warning')
                }}
              >
                {props.locale['SKIP']}
              </Button.Button8> */}
              <Button.Button7
                disabled={!((this.state.pdpaAgree || this.props.authState.pdpaVerified) && !this.state.isLoading)}
                style={{ padding: 0, width: 164, margin: '0 12px' }}
                onClick={() => {
                  if ((this.state.pdpaAgree || this.props.authState.pdpaVerified) && !this.state.isLoading) {
                    this.setState({ submit: true })
                    if (this.state.email && this.state.focus_id === '') {
                      if (!this.props.clubRegister.joined && this.state.password) {
                        this.setState({ isLoading: true })
                        this.handleSubmitForm()
                      }
                      if (this.props.clubRegister.joined) {
                        this.setState({ isLoading: true })
                        this.handleSubmitForm()
                      }
                    }
                  }
                }}
              >
                {props.locale['SAVE']}
              </Button.Button7>
              {this.state.isLoading ? <Loading /> : null}
            </div>
            <LineHorizontal />
            <div
              style={{ textAlign: 'center', color: '#3F3131', opacity: '0.2' }}
            >
              Powered by Invitree 2019
            </div>
          </div>
          <Modal
            isOpen={this.state.showModal}
            onRequestClose={() => this.setState({ showModal: false })}
            onRequestClose={() =>
              this.setState({
                showModal: false,
                modalInfo: { icon: '', text: '' },
              })
            }
            className="information__modal"
            overlayClassName="information__modal__overlay"
          >
            <img
              src={IconClose}
              alt=""
              className="information__modal__close"
              onClick={() =>
                this.setState({
                  showModal: false,
                  modalInfo: { icon: '', text: '' },
                })
              }
            />
            <img
              src={this.state.modalInfo.icon}
              alt=""
              className="information__modal__warning"
            />
            <p className="information___modal_text">
              {this.state.modalInfo.text}
            </p>
            <Button.Button7
              style={{ padding: 0, width: 164 }}
              onClick={this.state.modalInfo.action}
            >
              {props.locale['OK']}
            </Button.Button7>
          </Modal>
          {props.children}
        </div>
      </div>
    )
  }

  render() {
    return this.display(this.props)
  }
}
export default memo(Club)
