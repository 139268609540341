import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getLanguage } from '../../../locale'
import IconDown from '../../../images/ic_down.svg'

const SelectBox = styled.select`
  border: 1px solid #3f3131;
  width: ${({ width }) => `${width}`};
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  padding: 5px 5px 5px 10px;
  line-height: 35px;
  border-radius: 5px;
  background: url(${IconDown}) no-repeat right;
  -webkit-appearance: none;
  background-position-x: 90%;
`
const SelectBoxInfo = styled(SelectBox)`
  margin-bottom: 1em;
  padding: 5px 30px 5px 10px;
  background-position-x: 95%;
`

const yearRange = () => {
  const currentYear = new Date().getFullYear()
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  return range(currentYear, currentYear - 120, -1)
}

const monthRange = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  th: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
}

const StilWorkingData = ({
  props,
  handleSetWorkInformation,
  item,
  list,
  i,
}) => {
  if (!item) return null
  const [useWorkInformation, setWorkInformation] = useState(item)
  const {
    from_year,
    to_year,
    from_month,
    to_month,
    until_now,
    position,
    company,
    business_type,
    business_type_other,
    skill,
  } = useWorkInformation

  const handleOnChangeArray = (e, key, i) => {
    // let value = e.target.value
    // const newMapParam = { ...useWorkInformation, [key]: value }
    // setWorkInformation(newMapParam)
    // handleSetWorkInformation(newMapParam, i)
    return
  }
  // const handleOnChangeArrayUntilNow = (e, key, i) => {
  //   let value = e.target.checked
  //   let newMapParam = { ...useWorkInformation, [key]: value }
  //   if (value) {
  //     newMapParam.to_year = ''
  //     newMapParam.to_month = ''
  //   }
  //   setWorkInformation(newMapParam)
  //   handleSetWorkInformation(newMapParam, i)
  //   return
  // }
  useEffect(() => {
    // add from_year
    if (document.getElementById(`from_year${i}`)) {
      document.getElementById(`from_year${i}`).value = from_year || ''
      // document
      //   .getElementById(`position${i}`)
      //   .addEventListener('change', e => handleOnChangeArray(e, 'position', i))
    }

    // add from_month
    if (document.getElementById(`from_month${i}`)) {
      document.getElementById(`from_month${i}`).value = from_month || ''
      // document
      //   .getElementById(`position${i}`)
      //   .addEventListener('change', e => handleOnChangeArray(e, 'position', i))
    }
    // add to_year
    if (document.getElementById(`to_year${i}`)) {
      document.getElementById(`to_year${i}`).value = to_year || ''
      // document
      //   .getElementById(`position${i}`)
      //   .addEventListener('change', e => handleOnChangeArray(e, 'position', i))
    }
    // add to_month
    if (document.getElementById(`to_month${i}`)) {
      document.getElementById(`to_month${i}`).value = to_month || ''
      // document
      //   .getElementById(`position${i}`)
      //   .addEventListener('change', e => handleOnChangeArray(e, 'position', i))
    }
    // add position
    if (document.getElementById(`position${i}`)) {
      document.getElementById(`position${i}`).value = position || ''
      // document
      //   .getElementById(`position${i}`)
      //   .addEventListener('change', e => handleOnChangeArray(e, 'position', i))
    }
    //add company
    if (document.getElementById(`company${i}`)) {
      document.getElementById(`company${i}`).value = company || ''
      // document
      //   .getElementById(`company${i}`)
      //   .addEventListener('change', e => handleOnChangeArray(e, 'company', i))
    }
    if (document.getElementById(`until_now${i}`)) {
      document.getElementById(`until_now${i}`).checked = until_now || false
      document
        .getElementById(`until_now${i}`)
        .addEventListener('change', e => handleOnChangeArray(e, 'until_now', i))
    }
    if (business_type === '26') {
      //add business_type_other
      if (document.getElementById(`business_type_other${i}`)) {
        document.getElementById(
          `business_type_other${i}`
        ).value = business_type_other
        // document
        //   .getElementById(`business_type_other${i}`)
        //   .addEventListener('change', e =>
        //     handleOnChangeArray(e, 'business_type_other', i)
        //   )
      }
    }

    //add business_type
    if (document.getElementById(`business_type${i}`)) {
      document.getElementById(`business_type${i}`).value = business_type || ''
      // document
      //   .getElementById(`business_type${i}`)
      //   .addEventListener('change', e =>
      //     handleOnChangeArray(e, 'business_type', i)
      //   )
    }

    //add skill
    if (document.getElementById(`skill${i}`)) {
      document.getElementById(`skill${i}`).value = skill || ''
      // document
      //   .getElementById(`skill${i}`)
      //   .addEventListener('change', e => handleOnChangeArray(e, 'skill', i))
    }

    // return () => {
    //   //remove position
    //   if (document.getElementById('position')) {
    //     document
    //       .getElementById('position')
    //       .removeEventListener('change', e =>
    //         handleOnChangeArray(e, 'position', i)
    //       )
    //   }
    //   //remove company
    //   if (document.getElementById(`company${i}`)) {
    //     document
    //       .getElementById(`company${i}`)
    //       .removeEventListener('change', e =>
    //         handleOnChangeArray(e, 'company', i)
    //       )
    //   }
    //   //remove until_now
    //   if (document.getElementById(`until_now${i}`)) {
    //     document
    //       .getElementById(`until_now${i}`)
    //       .removeEventListener('change', e =>
    //         handleOnChangeArray(e, 'until_now', i)
    //       )
    //   }
    //   if (business_type === '26') {
    //     //remove business_type_other
    //     if (document.getElementById(`business_type_other${i}`)) {
    //       document
    //         .getElementById(`business_type_other${i}`)
    //         .removeEventListener('change', e =>
    //           handleOnChangeArray(e, 'business_type_other', i)
    //         )
    //     }
    //   }
    //   //remove business_type
    //   if (document.getElementById(`business_type${i}`)) {
    //     document
    //       .getElementById(`business_type${i}`)
    //       .removeEventListener('change', e =>
    //         handleOnChangeArray(e, 'business_type', i)
    //       )
    //   }

    //   //remove skill
    //   if (document.getElementById(`skill${i}`)) {
    //     document
    //       .getElementById(`skill${i}`)
    //       .removeEventListener('change', e =>
    //         handleOnChangeArray(e, 'skill', i)
    //       )
    //   }
    // }
  }, [])
  return (
    <div style={{ marginTop: 20 }}>
      <div className="flex flex-column-mobile">
        <div className="flex-auto mg-r-40">
          <p className="">
            {props.locale['FROM']}{' '}
            <SelectBox
              id={`from_year${i}`}
              name="from_year"
              width={'100px'}
              // onChange={e => handleOnChangeArray(e, 'from_year', i)}
              // value={from_year}
            >
              <option value="">{props.locale['YEAR']}</option>
              {yearRange().map((year, index) => {
                return (
                  <option key={year} value={index}>
                    {getLanguage().lang === 'EN' ? year : year + 543}
                  </option>
                )
              })}
            </SelectBox>
            <SelectBox
              id={`from_month${i}`}
              name="from_month"
              style={{ marginLeft: 10 }}
              width={'120px'}
              // onChange={e => handleOnChangeArray(e, 'from_month', i)}
              // value={from_month}
            >
              <option value="">{props.locale['MONTH']}</option>
              {monthRange[props.lang.toLowerCase()].map((lang, index) => {
                return (
                  <option key={lang} value={index}>
                    {lang}
                  </option>
                )
              })}
            </SelectBox>
          </p>
        </div>
        <div className="flex-auto ">
          <p className="">
            {props.locale['TO']}{' '}
            <SelectBox
              id={`to_year${i}`}
              name="to_year"
              style={{ opacity: until_now ? 0.5 : 1 }}
              width={'100px'}
              // onChange={e => handleOnChangeArray(e, 'to_year', i)}
              // value={to_year}
              disabled={until_now}
            >
              <option value="">{props.locale['YEAR']}</option>
              {yearRange().map((year, index) => {
                return (
                  <option key={year} value={index}>
                    {getLanguage().lang === 'EN' ? year : year + 543}
                  </option>
                )
              })}
            </SelectBox>
            <SelectBox
              id={`to_month${i}`}
              name="to_month"
              style={{ marginLeft: 10, opacity: until_now ? 0.5 : 1 }}
              width={'120px'}
              // onChange={e => handleOnChangeArray(e, 'to_month', i)}
              // value={to_month}
              disabled={until_now}
            >
              <option value="">{props.locale['MONTH']}</option>
              {monthRange[props.lang.toLowerCase()].map((lang, index) => {
                return (
                  <option key={lang} value={index}>
                    {lang}
                  </option>
                )
              })}
            </SelectBox>
            <label
              className={`checkbox-label flex-auto`}
              style={{ marginLeft: 10, paddingLeft: 32 }}
            >
              {props.locale['UNTIL_NOW']}
              <input
                id={`until_now${i}`}
                name="status"
                type="checkbox"
                // onChange={e => handleOnChangeArrayUntilNow(e, 'until_now', i)}
                // checked={until_now}
              />
              <span className={`checkmark `} />
            </label>
          </p>
        </div>
      </div>
      <div className="flex flex-column-mobile">
        <div className="flex-auto mg-r-40">
          <p className="information__label">{props.locale['POSITION']}</p>
          <input
            type="text"
            id={`position${i}`}
            placeholder={props.locale['ENTER_POSITION']}
            className="information__input"
            // onChange={e => handleOnChangeArray(e, 'position', i)}
            // value={position}
          />
        </div>
        <div className="flex-auto ">
          <p className="information__label">
            {props.locale['COMPANY_DEPARTMENT']}
          </p>
          <input
            type="text"
            placeholder={props.locale['ENTER_COMPANY_DEPARTMENT']}
            className="information__input"
            id={`company${i}`}
            // onChange={e => handleOnChangeArray(e, 'company', i)}
            // value={company}
          />
        </div>
      </div>
      <div className="flex flex-column-mobile">
        <div className="flex-auto mg-r-40">
          <p className="information__label">{props.locale['BUSINESS_TYPE']}</p>
          {list && (
            <SelectBoxInfo
              name="major"
              width={'100%'}
              id={`business_type${i}`}
              //   onChange={e => handleOnChangeArray(e, 'business_type', i)}
              //   value={business_type}
            >
              <option value="">{props.locale['CHOOSE']}</option>
              {list.business_type[props.lang.toLowerCase()].map(lang => {
                return (
                  <option key={lang.id} value={lang.id}>
                    {lang.value}
                  </option>
                )
              })}
            </SelectBoxInfo>
          )}
        </div>
        <div className="flex-auto">
          {business_type === '26' && (
            <input
              type="text"
              placeholder={props.locale['ENTER_BUSINESS_TYPE']}
              className="information__input margin-top-50"
              id={`business_type_other${i}`}
              //   onChange={e => handleOnChangeArray(e, 'business_type_other', i)}
              //   value={business_type_other}
            />
          )}
        </div>
      </div>
      <div className="flex flex-column-mobile">
        <div className="flex-auto">
          <p className="information__label">
            {props.locale['SKILL_EXPERIENCE']}
          </p>
          <input
            type="text"
            placeholder={props.locale['ENTER_SKILL_EXPERIENCE']}
            className="information__input"
            id={`skill${i}`}
            // onChange={e => handleOnChangeArray(e, 'skill', i)}
            // value={skill}
          />
        </div>
      </div>
    </div>
  )
}
export default StilWorkingData
