import React from 'react'

function Toggle({
  name,
  isOn = false,
  onWord,
  offWord,
  handleToggle,
  width = 'auto',
  ...props
}) {
  const styleLabel = isOn
    ? { background: '#77C150' }
    : { background: '#c5c2c2' }
  const classSpan = isOn
    ? 'react-switch-label--pd-r'
    : 'react-switch-label--pd-l'
  return (
    <React.Fragment>
      <input
        {...props}
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        name={name}
        id={name}
        type="checkbox"
      />
      <label
        className="react-switch-label"
        htmlFor={name}
        style={{ ...styleLabel, width }}
      >
        <span style={{ transition: 'all 0.3s' }} className={classSpan}>
          {isOn ? onWord : offWord}
        </span>
        <span className={`react-switch-button`} />
      </label>
    </React.Fragment>
  )
}

const areEqual = (prevProps, nextProps) => {
  return prevProps.isOn === nextProps.isOn
}

export default React.memo(Toggle, areEqual)
