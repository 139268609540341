import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Loading from '../../components/loading'
import View from '../../components/presentation/my-club-register'
import { BoxGray } from '../../components/template/box'
import authActions from '../../state/auth/actions'
import homeActions from '../../state/home/actions'
import clubRegisterActions from '../../state/club-register/actions'

class MyClubRegisterPage extends React.Component {
  componentDidMount() {
    this.props.checkAuthorization(null, null, null, {
      onSuccess: () => this.props.onLoad(),
    })
  }

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }
    if (!this.props.state.readyPageMyEvent) {
      return <Loading />
    }
    if (this.props.auth.currentUser !== null) {
      return (
        <Layout auth={this.props.auth.currentUser !== null} hideFooter={true}
                clubRegister={this.props.clubRegister}>
          <div>
            <SEO keywords={[`invitree`]} />
            <BoxGray>
              <View
                locale={this.props.app.locale[this.props.app.lang]}
                auth={this.props.auth.currentUser !== null}
                authState={this.props.auth}
                lang={this.props.app.lang}
                clubRegister={this.props.clubRegister}
                onSetProfile={this.props.onSetProfile}
              />
            </BoxGray>
          </div>
        </Layout>
      )
    }

    return (
      <div>
        <SEO keywords={[`invitree`]} />
        <BoxGray>
          <View
            locale={this.props.app.locale[this.props.app.lang]}
            auth={this.props.auth.currentUser !== null}
            authState={this.props.auth}
            lang={this.props.app.lang}
            clubRegister={this.props.clubRegister}
            onSetProfile={this.props.onSetProfile}
          />
        </BoxGray>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
    clubRegister: state.clubRegister,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: homeActions.onLoadMyEvent,
      onSetProfile: clubRegisterActions.onSetProfile,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyClubRegisterPage)
